import React, { useEffect, useRef, useState } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { useDrag, useDrop } from "react-dnd";
import { Tooltip } from "bootstrap";
import iconPath from "../../assets/not.png";

const DraggableTask = ({
  task,
  index,
  moveTask,
  handleToggleComplete,
  handleDelete,
  handleEditTask,
  openMenuId,
  setOpenMenuId,
  db,
  onElapsedTimeChange,
}) => {
  const [elapsedTime, setElapsedTime] = useState(task.elapsedTime || 0);
  const [isRunning, setIsRunning] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedTask, setEditedTask] = useState(task.task);
  const taskNameRef = useRef(null);
  const timerRef = useRef(null);
  const tooltipRef = useRef(null); // Reference for the tooltip instance
  const contextMenuRef = useRef(null);

  const [{ isDragging }, drag] = useDrag({
    type: "TODO",
    item: { index, task },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: () => !isEditing, // Disable dragging when editing
  });

  const [, drop] = useDrop({
    accept: "TODO",
    hover: (draggedItem) => {
      if (
        draggedItem.index !== index &&
        draggedItem.task.category === task.category
      ) {
        moveTask(draggedItem.index, index, task.category);
        draggedItem.index = index;
      }
    },
  });

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    if (hours > 99) {
      return `${hours}:${minutes.toString().padStart(2, "0")}:${secs
        .toString()
        .padStart(2, "0")}`;
    } else if (hours > 0) {
      return `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
    } else {
      return `${minutes.toString().padStart(2, "0")}:${secs
        .toString()
        .padStart(2, "0")}`;
    }
  };

  const updateElapsedTimeInDB = async () => {
    try {
      const taskRef = doc(db, "todos", task.id);
      await updateDoc(taskRef, { elapsedTime });
    } catch (error) {
      console.error("Error updating Firestore:", error);
    }
  };

  useEffect(() => {
    if (isRunning) {
      timerRef.current = setInterval(() => {
        setElapsedTime((prevTime) => {
          const newTime = prevTime + 1;
          onElapsedTimeChange(task.id, newTime);
          return newTime;
        });
      }, 1000);
    } else if (!isRunning && timerRef.current) {
      clearInterval(timerRef.current);
    }

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [isRunning, task.task, onElapsedTimeChange, task.id]);

  useEffect(() => {
    if (isRunning) {
      updateElapsedTimeInDB();
    }

    if (elapsedTime > 0 && elapsedTime % 900 === 0) {
      if (Notification.permission === "granted") {
        new Notification(
          `You've been working on "${task.task}" for ${
            elapsedTime / 60
          } minutes.`,
          {
            icon: iconPath,
            body: `Keep up the good work on "${task.task}"!`,
          }
        );
      }
    }
  }, [elapsedTime, isRunning, task.id, db, task.task]);

  useEffect(() => {
    if (Notification.permission !== "granted") {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
        }
      });
    }
  }, []);

  const handleTooltipInitialization = () => {
    if (taskNameRef.current) {
      const isOverflowing =
        taskNameRef.current.offsetWidth < taskNameRef.current.scrollWidth;

      // Dispose of existing tooltip if it exists
      if (tooltipRef.current) {
        tooltipRef.current.dispose();
        tooltipRef.current = null;
      }

      // Initialize tooltip if text is overflowing
      if (isOverflowing) {
        tooltipRef.current = new Tooltip(taskNameRef.current, {
          title: task.task,
          placement: "top",
          trigger: "hover focus",
        });
      }
    }
  };

  useEffect(() => {
    handleTooltipInitialization(); // Initial check on component mount

    const handleResize = () => {
      handleTooltipInitialization();
    };

    window.addEventListener("resize", handleResize); // Recalculate on window resize

    return () => {
      window.removeEventListener("resize", handleResize);
      if (tooltipRef.current) {
        tooltipRef.current.dispose(); // Clean up on unmount
        tooltipRef.current = null;
      }
    };
  }, [task.task, isEditing]); // Re-run on text change or after editing

  const handleStartPause = async () => {
    if (task.completed) {
      await handleToggleComplete(task);
    }
    setIsRunning((prevState) => !prevState);
  };

  const handleEditSave = async () => {
    if (editedTask.trim()) {
      await handleEditTask(task.id, editedTask.trim());
      setIsEditing(false);
      handleTooltipInitialization(); // Recheck tooltip after editing
    } else {
      alert("Task name cannot be empty.");
    }
  };

  const handleClickOutside = (event) => {
    if (
      contextMenuRef.current &&
      !contextMenuRef.current.contains(event.target)
    ) {
      setOpenMenuId(null);
    }
  };

  useEffect(() => {
    if (openMenuId === task.id) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openMenuId, task.id]);

  const handleToggleCompleteWrapper = () => {
    if (!task.completed) {
      setIsRunning(false);
    }
    handleToggleComplete(task);
  };

  return (
    <li
      ref={(node) => drag(drop(node))}
      className={`list-group-item`}
      style={{ opacity: isDragging ? 0.5 : 1 }}
    >
      <div className="task-container">
        <input
          type="checkbox"
          className="form-check-input me-2"
          checked={task.completed}
          onChange={handleToggleCompleteWrapper}
        />
        {isEditing ? (
          <input
            type="text"
            value={editedTask}
            onChange={(e) => setEditedTask(e.target.value)}
            onBlur={handleEditSave}
            onKeyDown={(e) => {
              if (e.key === "Enter") handleEditSave();
              if (e.key === "Escape") setIsEditing(false);
            }}
            className="form-control"
            autoFocus
          />
        ) : (
          <div
            ref={taskNameRef}
            className={`taskName ${task.completed ? "task-completed" : ""}`}
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-trigger="hover focus"
            title={task.task}
          >
            {task.task}
          </div>
        )}
        <div className="task-controls">
          <span style={{ marginRight: "10px" }}>{formatTime(elapsedTime)}</span>
          <button
            className={`btn btn-${isRunning ? "danger" : "success"} btn-sm`}
            onClick={handleStartPause}
          >
            {isRunning ? (
              <i className="bi bi-pause-fill"></i>
            ) : (
              <i className="bi bi-play-fill"></i>
            )}
          </button>
          <div className="position-relative ms-3">
            <button
              className="btn btn-link text-dark p-0"
              onClick={(e) => {
                e.stopPropagation();
                setOpenMenuId(openMenuId === task.id ? null : task.id);
              }}
              style={{ fontSize: "1.5rem" }}
            >
              <i className="bi bi-three-dots"></i>
            </button>
            {openMenuId === task.id && (
              <div
                className="context-menu position-absolute bg-white shadow-sm p-2 rounded"
                ref={contextMenuRef}
                style={{
                  right: 0,
                  top: "100%",
                  zIndex: 1000,
                  border: "1px solid #ccc",
                  minWidth: "auto",
                  whiteSpace: "nowrap",
                }}
              >
                <div
                  className="context-menu-item"
                  style={{
                    cursor: "pointer",
                    padding: "5px 10px",
                    fontSize: "0.9rem",
                  }}
                  onClick={() => setIsEditing(true)}
                >
                  <i className="bi bi-pencil-square me-2"></i> Edit
                </div>
                <div
                  className="context-menu-item"
                  style={{
                    cursor: "pointer",
                    padding: "5px 10px",
                    fontSize: "0.9rem",
                  }}
                  onClick={() => handleDelete(task.id)}
                >
                  <i className="bi bi-trash-fill me-2"></i> Delete
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </li>
  );
};

export default DraggableTask;
