import React, { useEffect, useState, useCallback, useMemo } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { MultiBackend, TouchTransition } from "dnd-multi-backend";
import Select from "react-select";
import {
  collection,
  addDoc,
  query,
  where,
  getDocs,
  updateDoc,
  deleteDoc,
  doc,
  orderBy,
  writeBatch,
} from "firebase/firestore";
import { auth, db } from "../../firebase";
import DroppableCategory from "./DroppableCategory";
import "./Todo.scss";

function Todo() {
  const [task, setTask] = useState("");
  const [tasks, setTasks] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openMenuId, setOpenMenuId] = useState(null);
  const [groupedTasks, setGroupedTasks] = useState({});

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (user) {
      loadTodos();
      loadCategories();
    }
  }, [user]);

  useEffect(() => {
    calculateGroupedTasks();
  }, [tasks, categories]);

  const loadTodos = useCallback(async () => {
    try {
      const q = query(
        collection(db, "todos"),
        where("uid", "==", user.uid),
        orderBy("category"),
        orderBy("order")
      );
      const querySnapshot = await getDocs(q);
      const todos = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setTasks(todos);
    } catch (error) {
      console.error("Error loading todos:", error);
    }
  }, [user]);

  const loadCategories = useCallback(async () => {
    try {
      const q = query(
        collection(db, "categories"),
        where("uid", "==", user.uid),
        orderBy("order"),
        orderBy("name")
      );
      const querySnapshot = await getDocs(q);
      const categories = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCategories(categories);
    } catch (error) {
      console.error("Error loading categories:", error);
    }
  }, [user]);

  const handleAddTodo = async (e) => {
    e.preventDefault();
    if (task && selectedCategory) {
      const newOrder =
        tasks.length > 0 ? Math.max(...tasks.map((t) => t.order)) + 1 : 0;
      await addDoc(collection(db, "todos"), {
        uid: user.uid,
        task,
        category: selectedCategory.value,
        completed: false,
        order: newOrder,
        elapsedTime: 0,
      });
      setTask("");
      loadTodos();
    }
  };

  const handleToggleComplete = useCallback(
    async (todo) => {
      const todoRef = doc(db, "todos", todo.id);
      await updateDoc(todoRef, {
        completed: !todo.completed,
      });
      setTasks((prevTasks) =>
        prevTasks.map((t) =>
          t.id === todo.id ? { ...t, completed: !t.completed } : t
        )
      );
    },
    [tasks]
  );

  const handleEditTask = useCallback(
    async (todoId, newTask) => {
      const todoRef = doc(db, "todos", todoId);
      await updateDoc(todoRef, {
        task: newTask,
      });
      setTasks((prevTasks) =>
        prevTasks.map((t) => (t.id === todoId ? { ...t, task: newTask } : t))
      );
    },
    [tasks]
  );

  const handleDelete = useCallback(
    async (todoId) => {
      await deleteDoc(doc(db, "todos", todoId));
      setTasks((prevTasks) => prevTasks.filter((t) => t.id !== todoId));
    },
    [tasks]
  );

  const handleDeleteAll = useCallback(
    async (category) => {
      const batch = writeBatch(db);
      const tasksToDelete = tasks.filter((task) => task.category === category);

      tasksToDelete.forEach((task) => {
        const taskRef = doc(db, "todos", task.id);
        batch.delete(taskRef);
      });

      await batch.commit();
      setTasks((prevTasks) =>
        prevTasks.filter((task) => task.category !== category)
      );
    },
    [tasks]
  );

  const moveTask = useCallback(
    (fromIndex, toIndex, category) => {
      setTasks((prevTasks) => {
        const newTasks = prevTasks
          .filter((task) => task.category === category)
          .slice();
        const [movedTask] = newTasks.splice(fromIndex, 1);
        newTasks.splice(toIndex, 0, movedTask);

        const allOtherTasks = prevTasks.filter(
          (task) => task.category !== category
        );

        const updatedTasks = [
          ...allOtherTasks,
          ...newTasks.map((task, index) => ({ ...task, order: index })),
        ];

        return updatedTasks;
      });

      setTimeout(async () => {
        try {
          const reorderedTasks = tasks
            .filter((task) => task.category === category)
            .slice();
          const [movedTask] = reorderedTasks.splice(fromIndex, 1);
          reorderedTasks.splice(toIndex, 0, movedTask);

          const batch = writeBatch(db);
          reorderedTasks.forEach((task, index) => {
            const taskRef = doc(db, "todos", task.id);
            batch.update(taskRef, { order: index });
          });
          await batch.commit();
        } catch (error) {
          console.error("Error updating Firestore:", error);
        }
      }, 100);
    },
    [tasks]
  );

  const calculateGroupedTasks = useCallback(() => {
    const grouped = categories.reduce((acc, category) => {
      const categoryTasks = tasks.filter(
        (task) => task.category === category.name
      );
      const totalElapsedTime = categoryTasks.reduce(
        (total, task) => total + task.elapsedTime,
        0
      );
      acc[category.name] = {
        tasks: categoryTasks,
        totalElapsedTime,
      };
      return acc;
    }, {});

    setGroupedTasks(grouped);
  }, [categories, tasks]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <DndProvider
      backend={MultiBackend}
      options={{
        backends: [
          {
            backend: HTML5Backend,
            preview: true,
          },
          {
            backend: TouchBackend,
            options: { enableMouseEvents: true },
            preview: true,
            transition: TouchTransition,
          },
        ],
      }}
    >
      <div className="container todo">
        <div className="row justify-content-md-center">
          <div className="col-lg-8">
            <h2 className="text-center">To-Do List</h2>

            <form className="add-task" onSubmit={handleAddTodo}>
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="newTask"
                  placeholder="New task"
                  value={task}
                  onChange={(e) => setTask(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <Select
                  id="category"
                  options={categories.map((category) => ({
                    value: category.name,
                    label: category.name,
                  }))}
                  value={selectedCategory}
                  onChange={setSelectedCategory}
                  isSearchable
                  placeholder="Select a category"
                />
              </div>
              <button type="submit" className="btn btn-primary w-100 mt-3 add">
                Add
              </button>
            </form>
            <div>
              {Object.entries(groupedTasks).map(
                ([category, { tasks, totalElapsedTime }]) =>
                  tasks.length > 0 && (
                    <DroppableCategory
                      key={category}
                      category={category}
                      tasks={tasks}
                      totalElapsedTime={totalElapsedTime} // Pass the total elapsed time
                      moveTask={moveTask}
                      handleToggleComplete={handleToggleComplete}
                      handleEditTask={handleEditTask}
                      handleDelete={handleDelete}
                      handleDeleteAll={handleDeleteAll}
                      openMenuId={openMenuId}
                      setOpenMenuId={setOpenMenuId}
                      db={db}
                      calculateGroupedTasks={calculateGroupedTasks} // Pass the function
                    />
                  )
              )}
            </div>
          </div>
        </div>
      </div>
    </DndProvider>
  );
}

export default Todo;
