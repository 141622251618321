import React, { useEffect, useState } from "react";
import { useDrop } from "react-dnd";
import DraggableTask from "./DraggableTask";

const DroppableCategory = ({
  category,
  tasks,
  totalElapsedTime, // Receive the total elapsed time
  moveTask,
  handleToggleComplete,
  handleDelete,
  handleEditTask,
  handleDeleteAll,
  openMenuId,
  setOpenMenuId,
  db,
  calculateGroupedTasks, // Receive the function as a prop
}) => {
  const [, drop] = useDrop({
    accept: "TODO",
    hover: () => {},
  });

  const [allCompleted, setAllCompleted] = useState(false);

  useEffect(() => {
    setAllCompleted(tasks.length > 0 && tasks.every((task) => task.completed));
  }, [tasks]);

  // Format time helper function
  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    if (hours > 99) {
      return `${hours}:${minutes.toString().padStart(2, "0")}:${secs
        .toString()
        .padStart(2, "0")}`;
    } else if (hours > 0) {
      return `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
    } else {
      return `${minutes.toString().padStart(2, "0")}:${secs
        .toString()
        .padStart(2, "0")}`;
    }
  };

  return (
    <div ref={drop} className="mt-4">
      <div className="d-flex align-items-center justify-content-between py-3 cat-bar">
        <h3>{category}</h3>

        <div className="progress">
          <div
            className="progress-bar"
            role="progressbar"
            style={{
              width: `${
                (tasks.filter((task) => task.completed).length / tasks.length) *
                100
              }%`,
              backgroundColor: `hsl(${
                (tasks.filter((task) => task.completed).length / tasks.length) *
                100
              }, 100%, 50%)`,
            }}
            aria-valuenow={tasks.filter((task) => task.completed).length}
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
        <div className="text-muted">
          Total Time: {formatTime(totalElapsedTime)} {/* Display total time */}
        </div>
      </div>
      <ul className="list-group">
        {tasks.map((todo, index) => (
          <DraggableTask
            key={todo.id}
            task={todo}
            index={index}
            moveTask={moveTask}
            handleToggleComplete={handleToggleComplete}
            handleEditTask={handleEditTask}
            handleDelete={handleDelete}
            openMenuId={openMenuId}
            setOpenMenuId={setOpenMenuId}
            db={db}
            onElapsedTimeChange={(taskId, newTime) => {
              // Notify the parent of the elapsed time change
              todo.elapsedTime = newTime;
              calculateGroupedTasks(); // Call the function passed from Todo.js
            }}
          />
        ))}
      </ul>
      {allCompleted && (
        <button
          className="btn btn-danger btn-sm mt-1 delete-all"
          data-bs-toggle="modal"
          data-bs-target="#deleteAllModal"
        >
          <i className="bi bi-x-octagon-fill"></i> Clear Complete
        </button>
      )}

      {/* Delete All Confirmation Modal */}
      <div
        className="modal fade"
        id="deleteAllModal"
        tabIndex="-1"
        aria-labelledby="deleteAllModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="deleteAllModalLabel">
                Confirm Delete All
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              Are you sure you want to delete all completed tasks in the "
              {category}" category?
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => {
                  handleDeleteAll(category);
                  document.querySelector("#deleteAllModal .btn-close").click();
                }}
              >
                Delete All
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(DroppableCategory);
