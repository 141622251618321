import React from "react";
import "./Footer.scss";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const buildNumber = process.env.REACT_APP_BUILD_NUMBER || "N/A";

  return (
    <footer className="bg-dark text-white mt-auto py-3 text-center">
      <div className="container">
        <p>&copy; {currentYear} Higgins Development - Build {buildNumber}</p>
      </div>
    </footer>
  );
};

export default Footer;
