import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { auth, db, storage } from "../../firebase";
import {
  updateProfile,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from "firebase/auth";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router-dom";
import zxcvbn from "zxcvbn";
import { getPasswordStrengthMessage } from "../../utils/passwordUtils";
import "./Account.scss";

function Account() {
  const [user, setUser] = useState(null);
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [profilePicture, setProfilePicture] = useState(null);
  const [profilePictureUrl, setProfilePictureUrl] = useState("");
  const [passwordStrength, setPasswordStrength] = useState(null);
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [notificationPermission, setNotificationPermission] = useState(
    Notification.permission
  );
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      if (auth.currentUser) {
        const userDoc = await getDoc(doc(db, "users", auth.currentUser.uid));
        const userData = userDoc.data();
        setUser(auth.currentUser);
        setName(userData.name);
        setProfilePictureUrl(userData.profilePicture);
      }
    };

    fetchUserData();

    // Check the initial state of notification permissions
    if (Notification.permission === "granted") {
      setNotificationPermission("granted");
    } else if (Notification.permission === "denied") {
      setNotificationPermission("denied");
    } else {
      setNotificationPermission("default");
    }
  }, []);

  const handlePasswordChange = (e) => {
    const password = e.target.value;
    setPassword(password);
    setPasswordStrength(zxcvbn(password).score);
  };

  const handleConfirmPasswordChange = (e) => {
    const confirmPassword = e.target.value;
    setConfirmPassword(confirmPassword);
    setPasswordMatch(password === confirmPassword);
  };

  const handleUpdateProfile = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setPasswordMatch(false);
      return;
    }

    if (passwordStrength !== null && passwordStrength < 2) {
      alert("Password is too weak. Please choose a stronger password.");
      return;
    }

    try {
      let newProfilePictureUrl = profilePictureUrl;
      if (profilePicture) {
        const profilePictureRef = ref(storage, `profilePictures/${user.uid}`);
        await uploadBytes(profilePictureRef, profilePicture);
        newProfilePictureUrl = await getDownloadURL(profilePictureRef);
        await updateProfile(user, { photoURL: newProfilePictureUrl });
        await updateDoc(doc(db, "users", user.uid), {
          profilePicture: newProfilePictureUrl,
        });
      }

      if (name) {
        await updateProfile(user, { displayName: name });
        await updateDoc(doc(db, "users", user.uid), { name });
      }

      if (password) {
        const credential = EmailAuthProvider.credential(user.email, password);
        await reauthenticateWithCredential(user, credential);
        await updatePassword(user, password);
      }

      setSuccess("Profile updated successfully!");
      setError("");
      setProfilePictureUrl(newProfilePictureUrl);
    } catch (error) {
      console.error("Error updating profile:", error);
      setError("Failed to update profile.");
      setSuccess("");
    }
  };

  const requestNotificationPermission = async () => {
    const permission = await Notification.requestPermission();
    setNotificationPermission(permission);

    const modalMessage =
      permission === "granted"
        ? "Notifications have been enabled!"
        : "Notifications were denied. You can enable them in your browser settings.";

    setModalMessage(modalMessage);
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  return (
    <div className="container">
      <div className="row justify-content-md-center">
        <div className="col-lg-8">
          <h2 className="text-center">Account Management</h2>
          {error && <div className="alert alert-danger">{error}</div>}
          {success && <div className="alert alert-success">{success}</div>}
          <form onSubmit={handleUpdateProfile}>
            <div className="mb-3 py-3">
              {profilePictureUrl && (
                <div className="mb-3 text-center">
                  <img
                    src={profilePictureUrl}
                    alt="Profile"
                    className="img-thumbnail"
                    style={{ width: "150px", height: "150px" }}
                  />
                </div>
              )}
              <label htmlFor="name" className="form-label">
                Name
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="profilePicture" className="form-label">
                Profile Picture
              </label>
              <input
                type="file"
                className="form-control"
                id="profilePicture"
                onChange={(e) => setProfilePicture(e.target.files[0])}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">
                New Password
              </label>
              <input
                type="password"
                className="form-control"
                id="password"
                placeholder="Leave blank to keep current password"
                value={password}
                onChange={handlePasswordChange}
              />
              {passwordStrength !== null && (
                <small className="form-text text-muted">
                  Password strength:{" "}
                  {getPasswordStrengthMessage(passwordStrength)}
                </small>
              )}
            </div>
            <div className="mb-3">
              <label htmlFor="confirmPassword" className="form-label">
                Confirm New Password
              </label>
              <input
                type="password"
                className="form-control"
                id="confirmPassword"
                placeholder="Confirm new password"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
              />
              {!passwordMatch && (
                <small className="form-text text-danger">
                  Passwords do not match
                </small>
              )}
            </div>

            <button
              type="button"
              className="btn btn-info w-100 mb-3"
              onClick={requestNotificationPermission}
              disabled={notificationPermission === "granted"}
            >
              {notificationPermission === "granted"
                ? "Notifications Enabled"
                : "Enable Notifications"}
            </button>

            <button type="submit" className="btn btn-primary w-100 mt-3">
              Update Profile
            </button>
          </form>
        </div>
      </div>

      {/* Bootstrap Modal */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Notification Permission</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Account;
