import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav, NavDropdown, Image, Container } from "react-bootstrap";
import SlideOutMenu from "../SlideOutMenu/SlideOutMenu";
import { signOut } from "firebase/auth";
import { auth, db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import useAuth from "../../hooks/useAuth";
import "./Header.scss";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { user } = useAuth();
  const [name, setName] = useState("");
  const [profilePictureUrl, setProfilePictureUrl] = useState("");

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleLogout = async () => {
    await signOut(auth);
    setMenuOpen(false); // Ensure the menu is closed after logging out
  };

  useEffect(() => {
    const fetchUserData = async () => {
      if (auth.currentUser) {
        const userDoc = await getDoc(doc(db, "users", auth.currentUser.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setName(userData.name || "");
          setProfilePictureUrl(userData.profilePicture || "");
        }
      }
    };

    fetchUserData();
  }, []);

  return (
    <>
      <Navbar bg="dark" variant="dark" expand="lg" className="mb-4">
        <Container fluid>
          <Navbar.Brand as={Link} to="/">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path
                fill="#0b5ed7"
                d="M10,17L6,13L7.41,11.59L10,14.17L16.59,7.58L18,9M12,3A1,1 0 0,1 13,4A1,1 0 0,1 12,5A1,1 0 0,1 11,4A1,1 0 0,1 12,3M19,3H14.82C14.4,1.84 13.3,1 12,1C10.7,1 9.6,1.84 9.18,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5A2,2 0 0,0 19,3Z"
              />
            </svg>{" "}
            Do That Today!
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbar-nav" onClick={toggleMenu} />
          <Navbar.Collapse id="navbar-nav" className="d-none d-lg-block">
            <Nav className="ms-auto">
              {user ? (
                <>
                  <Nav.Link as={Link} to="/todo">
                    Todo
                  </Nav.Link>
                  <Nav.Link as={Link} to="/manage-categories">
                    Manage Categories
                  </Nav.Link>
                  <NavDropdown
                    title={
                      <Image
                        src={profilePictureUrl || "/default.jpg"}
                        alt="Profile"
                        roundedCircle
                        width="30"
                        height="30"
                      />
                    }
                    id="profile-dropdown"
                    align="end"
                  >
                    <NavDropdown.Item as={Link} to="/account">
                      Account
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={handleLogout}>
                      Logout
                    </NavDropdown.Item>
                  </NavDropdown>
                </>
              ) : (
                <>
                  <Nav.Link as={Link} to="/login">
                    Login
                  </Nav.Link>
                  <Nav.Link as={Link} to="/register">
                    Register
                  </Nav.Link>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <SlideOutMenu
        isOpen={menuOpen}
        toggleMenu={toggleMenu}
        handleLogout={handleLogout}
        user={{ ...user, name, profilePicture: profilePictureUrl }}
      />
    </>
  );
};

export default Header;
