// src/App.js
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import "bootstrap/dist/js/bootstrap.bundle.min"; // Import Bootstrap JS for dropdown functionality
import Todo from "./components/Todo/Todo";
import ManageCategories from "./components/ManageCategories/ManageCategories";
import Login from "./components/Login/Login";
import Register from "./components/Register/Register";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import PrivateRoute from "./routes/PrivateRoute";
import NotFound from "./components/NotFound/NotFound";
import useAuth from "./hooks/useAuth";
import Account from "./components/Account/Account";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import "./styles/App.scss";

function App() {
  const { user, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <Header />
      <div className="main">
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route
            path="/todo"
            element={
              <PrivateRoute>
                <Todo />
              </PrivateRoute>
            }
          />
          <Route
            path="/manage-categories"
            element={
              <PrivateRoute>
                <ManageCategories />
              </PrivateRoute>
            }
          />
          <Route
            path="/"
            element={user ? <Navigate to="/todo" /> : <Navigate to="/login" />}
          />
          <Route path="*" element={<NotFound />} />
          <Route
            path="/account"
            element={
              <PrivateRoute>
                <Account />
              </PrivateRoute>
            }
          />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
