// SlideOutMenu.js
import React from "react";
import { Link } from "react-router-dom";
import "./SlideOutMenu.scss";

const SlideOutMenu = ({ isOpen, toggleMenu, handleLogout, user }) => {
  return (
    <>
      <div className={`slide-out-menu ${isOpen ? "open" : ""}`}>
        <div className="menu-content">
          {user && (
            <>
              <div className="profile-section">
                <a href="/account">
                  {" "}
                  <img
                    src={user.profilePicture || "/default.jpg"}
                    alt="Profile"
                    className="rounded-circle"
                    width="50"
                    height="50"
                  />
                  <h5>{user.name}</h5>
                </a>
              </div>
              <Link to="/todo" className="menu-item" onClick={toggleMenu}>
                Todo
              </Link>
              <Link
                to="/manage-categories"
                className="menu-item"
                onClick={toggleMenu}
              >
                Manage Categories
              </Link>
              <Link to="/account" className="menu-item" onClick={toggleMenu}>
                Account
              </Link>
              <button
                className="menu-item btn btn-primary w-100 mt-3"
                onClick={handleLogout}
              >
                Logout
              </button>
            </>
          )}
          {!user && (
            <>
              <Link to="/login" className="menu-item" onClick={toggleMenu}>
                Login
              </Link>
              <Link to="/register" className="menu-item" onClick={toggleMenu}>
                Register
              </Link>
            </>
          )}
        </div>
      </div>
      {isOpen && <div className="menu-overlay" onClick={toggleMenu}></div>}
    </>
  );
};

export default SlideOutMenu;
