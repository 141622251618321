import React, { useState, useEffect } from "react";
import { auth, db } from "../../firebase";
import {
  collection,
  addDoc,
  getDocs,
  deleteDoc,
  doc,
  query,
  where,
  updateDoc,
  orderBy,
  writeBatch,
} from "firebase/firestore";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const ItemType = "CATEGORY";

const DraggableCategory = ({
  category,
  index,
  moveCategory,
  handleDeleteCategory,
}) => {
  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: { index, category },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: ItemType,
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveCategory(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  return (
    <li
      ref={(node) => drag(drop(node))}
      className="list-group-item d-flex justify-content-between align-items-center"
      style={{ opacity: isDragging ? 0.5 : 1 }}
    >
      <span>{category.name}</span>
      <button
        className="btn btn-danger btn-sm"
        onClick={() => handleDeleteCategory(category.id)}
      >
        Delete
      </button>
    </li>
  );
};

const DroppableCategoryList = ({
  categories,
  moveCategory,
  handleDeleteCategory,
}) => {
  const [, drop] = useDrop({
    accept: ItemType,
    hover: () => {},
  });

  return (
    <div ref={drop} className="mt-3">
      <h5 className="cat-title">Categories</h5>
      <ul className="list-group list-group-cat">
        {categories.map((category, index) => (
          <DraggableCategory
            key={category.id}
            category={category}
            index={index}
            moveCategory={moveCategory}
            handleDeleteCategory={handleDeleteCategory}
          />
        ))}
      </ul>
    </div>
  );
};

function ManageCategories() {
  const [category, setCategory] = useState("");
  const [categories, setCategories] = useState([]);
  const user = auth.currentUser;

  const loadCategories = async () => {
    if (!user) return;
    const q = query(
      collection(db, "categories"),
      where("uid", "==", user.uid),
      orderBy("order"),
      orderBy("name")
    );
    const querySnapshot = await getDocs(q);
    const categories = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setCategories(categories);
  };

  useEffect(() => {
    loadCategories();
  }, [user]);

  const handleAddCategory = async (e) => {
    e.preventDefault();
    if (category && user) {
      const newOrder =
        categories.length > 0
          ? Math.max(...categories.map((c) => c.order)) + 1
          : 0;
      await addDoc(collection(db, "categories"), {
        uid: user.uid,
        name: category,
        order: newOrder,
      });
      setCategory("");
      loadCategories();
    }
  };

  const handleDeleteCategory = async (categoryId) => {
    await deleteDoc(doc(db, "categories", categoryId));
    loadCategories();
  };

  const moveCategory = (fromIndex, toIndex) => {
    setCategories((prevCategories) => {
      const newCategories = Array.from(prevCategories);
      const [movedCategory] = newCategories.splice(fromIndex, 1);
      newCategories.splice(toIndex, 0, movedCategory);

      return newCategories;
    });

    // Delay Firestore update to ensure UI updates first
    setTimeout(async () => {
      const reorderedCategories = Array.from(categories);
      const [movedCategory] = reorderedCategories.splice(fromIndex, 1);
      reorderedCategories.splice(toIndex, 0, movedCategory);

      // Update Firestore with the new order
      const batch = writeBatch(db);
      reorderedCategories.forEach((category, index) => {
        const categoryRef = doc(db, "categories", category.id);
        batch.update(categoryRef, { order: index });
      });
      await batch.commit();
    }, 100);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="container">
        <div className="row justify-content-md-center">
          <div className="col-lg-8">
            <h2 className="text-center">Manage Categories</h2>
            <form onSubmit={handleAddCategory} id="categoryForm">
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="newCategory"
                  placeholder="Category name"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                />
              </div>
              <button type="submit" className="btn btn-primary w-100 mt-3">
                Add Category
              </button>
            </form>
            <DroppableCategoryList
              categories={categories}
              moveCategory={moveCategory}
              handleDeleteCategory={handleDeleteCategory}
            />
          </div>
        </div>
      </div>
    </DndProvider>
  );
}

export default ManageCategories;
